<template>
  <v-dialog v-model="dialog" scrollable max-width="500px">
        <template v-slot:activator="{ on }">
          <v-btn text color="grey " dark v-on="on" class="pa-0"><v-icon class="mx-1">speaker_notes</v-icon> Prompts</v-btn>
        </template>
        <v-card>
          <v-card-title class="gradientBG white--text">Available Speech Commands</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 500px;">
            <h2 class="mt-2 secondary--text">Play <v-icon color="secondary">play_arrow</v-icon></h2>
            <ul>
              <li>"Play"</li>
              <li>"Next"</li>
              <li>"Continue"</li>
            </ul>
            <h2 class="mt-4 secondary--text">Replay <v-icon color="secondary">replay</v-icon></h2>
            <ul>
              <li>"Replay"</li>
              <li>"Rewind"</li>
              <li>"Repeat"</li>
            </ul>
            <h2 class="mt-4 secondary--text">Skip <v-icon color="secondary">fast_forward</v-icon></h2>
            <ul>
              <li>"Skip Step"</li>
            </ul>
            <h2 class="mt-4 secondary--text">Choose Step <v-icon color="secondary">playlist_play</v-icon></h2>
            <ul>
              <li>"Go to step #"</li>
            </ul>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" large block @click="dialog = false">Got it!</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
export default {
  name: "commandsDialog",
  data(){
    return{
      dialog: false
    }
  },
  methods:{

  }
};
</script>

<style lang="scss">
  #nav{
    width: 100%;
    z-index: 9999;
    border-bottom: 1px solid #e6e6e6;
    .v-toolbar__items{
      .v-btn{height: 44px!important;}
    }
    .logo{
      max-width:150px;
    }
  }
</style>
